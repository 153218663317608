import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { CalculatorForm } from './calculator/calculator.component';

let routes = [
    { path: '', component: AppComponent, pathMatch: 'full' }//,
    //{ path: '', component: CalculatorForm, pathMatch: 'full' }//,
    //{ path: 'de/kontakt', component: ContactForm, pathMatch: 'full' },
    //{ path: '**'  }
];

@NgModule({
  declarations: [
        AppComponent,
        CalculatorForm
  ],
  imports: [
      BrowserModule,
      ReactiveFormsModule,
      BrowserAnimationsModule,
      ToastrModule.forRoot(),
      HttpClientModule,
      RouterModule.forRoot(routes, {
          useHash: false,
          enableTracing: false //for debugging of routes
      })
    ],
    exports: [
        
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
