<section class="w-100 bg-primary" id="calcform">
    <div class="container py-5 text-light">

        <header class="text-center mb-5">
            <h2 class="dinslab">Worauf warten Sie?</h2>
            <p>Berechnen Sie Ihren persönlichen Projektpreis!</p>
        </header>

        <div class="row">

            <div class="col-md-7 order-1 order-md-2">
                <form [formGroup]="calculatorForm" (ngSubmit)="onSubmit()">
                    <div asp-validation-summary="ModelOnly"></div>
                    <div class="form-group row">
                        <label asp-for="Name" class="col-sm-3 col-form-label" for="Name">
                            Name
                        </label>
                        <div class="col-sm-9">
                            <input type="text" formControlName="Name" class="form-control" />
                            <span asp-validation-for="Name"></span>
                        </div>

                    </div>
                    <div class="form-group row">
                        <label asp-for="Email" class="col-sm-3 col-form-label" for="Email">
                            E-Mail
                        </label>
                        <div class="col-sm-9">
                            <input type="text" formControlName="Email" class="form-control" />
                            <span asp-validation-for="Email"></span>
                        </div>

                    </div>
                    <div class="form-group row">
                        <label asp-for="Subject" class="col-sm-3 col-form-label" for="Subject">
                            Betreff
                        </label>
                        <div class="col-sm-9">
                            <input type="text" formControlName="Subject" class="form-control" />
                            <span asp-validation-for="Subject"></span>
                        </div>

                    </div>
                    <div class="form-group row">
                        <label asp-for="Message" class="col-sm-3 col-form-label" for="Message">
                            Nachricht
                        </label>
                        <div class="col-sm-9">
                            <textarea rows="4" formControlName="Message" class="form-control"></textarea>
                            <span asp-validation-for="Message"></span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-9 offset-sm-3">
                            <button type="submit" [disabled]="!calculatorForm.valid" class="btn btn-light">Nachricht senden</button>

                        </div>
                    </div>
                </form>
            </div>

            <div class="col-md-5 order-2 order-md-1 pt-5 pt-md-0">
                <h3 class="dinslab h5">Berechnung</h3>
                <p>
                    Ihr Projektpreis:
                </p>

            </div>

        </div>
    </div>
</section>
