import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  template: 'app.component.html',
  styles: []
})
export class AppComponent {
    title = 'pictureone-app';
    public routeurl: string = "";

    constructor(private router: Router) { }

    ngOnInit() {
        this.routeurl = this.router.url;
        //console.log(this.routeurl);
    }
}
