import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';

import { CalculatorService } from '../calculator/calculator.service';
import { CalculatorModel } from '../calculator/calculator.model';
//import { HttpHandler } from '@angular/common/http';

@Component({
    selector: "calculator",
    templateUrl: "calculator.component.html",
    styleUrls: []
})
export class CalculatorForm implements OnInit {
    calculator: CalculatorModel;
    loading = true;

    //constructor(private fb: FormBuilder) { }
    constructor(private fb: FormBuilder, private cfs: CalculatorService, private toastr: ToastrService) { }
    //constructor(private fb: FormBuilder, private toastr: ToastrService) { }

    ngOnInit() {
        this.loading = false;
    }

    calculatorForm = this.fb.group({
        Name: new FormControl('', Validators.required),
        Email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
        Subject: new FormControl('', Validators.required),
        Message: new FormControl('', Validators.required)
    });

    onSubmit() {
        //Werte in Console ausgeben
        this.loading = true;
        this.calculator = new CalculatorModel([this.calculatorForm.value]);
        this.calculatorForm.disable();
        console.log(this.calculator);

        //this.loading = true;
        //this.toastr.success('Super, die Nachricht wurde versandt.');

        //Don't forget to subscribe on an observable, or it will never be called.
        this.cfs.sendMail(this.calculator).subscribe(
            () => {
                //Success
                this.loading = false;
                this.toastr.success('Super, die Nachricht wurde versandt.');
                this.calculatorForm.reset();
                this.calculatorForm.enable();
            },
            () => {
                //Failed
                //console.warn(JSON.stringify(this.contact));
                this.loading = false;
                this.toastr.error('Oops, da ist was schief gelaufen.');
                this.calculatorForm.enable();
            }
        );

    }

}