import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

import { CalculatorModel } from './calculator.model';

@Injectable()
export class CalculatorService {
	constructor(private http: HttpClient) { }

	sendMail(contact: CalculatorModel): Observable<object> {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		//console.log(contact);
		var obj = JSON.stringify(contact).replace('{"0":', '').replace('}}', '}');
		console.log(obj);
		return this.http.post("/api/contactform", obj, { headers: headers })
			.pipe(
				//When it fails, we're going to retry 3 times.
				retry(3)
			);
		//return this.http.post("http://localhost:54045/api/contactformsender", JSON.stringify(contact))
		//	.pipe();
		//return null;
	}
}


//@Injectable()
//export class ContactFormService {
//	constructor(private http: HttpClient) { }

//	sendMail(contact: ContactFormModel): Observable<object> {
//		//Setting json header so other applications can parse our message.
//		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
//		return this.http.post(environment.target, JSON.stringify(contact), { headers: headers })
//			.pipe(
//				//When it fails, we're going to retry 3 times.
//				retry(3)
//			);
//	}

//	//sendMail(contact: ContactFormModel): Observable<object> {
//	//	//Setting json header so other applications can parse our message.
//	//	const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
//	//	return this.http.post(environment.target, JSON.stringify(contact), { headers: headers })
//	//		.pipe(
//	//			//When it fails, we're going to retry 3 times.
//	//			retry(3)
//	//		);
//	//}
//}